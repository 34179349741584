<template>
   <div class="header">
      <div class="inner-header flex">
         <img src="../../assets/img/SudaniWhiteYellow.png" alt=""/>
      </div>
      <div>
         <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
               <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
               <g class="parallax">
                  <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                  <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                  <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                  <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
               </g>
         </svg>
      </div>
   </div>
        <!--Header ends-->
    <main id="content">
       <div class="content flex">
            <form @submit.prevent="unsubscribe">
               <p class="" style="font-size:  25px; width:80%; font-weight: bold; "> إلغاء الإشتراك  </p>
                <p class="sub-title mb-3 text-center zoal " style="font-size:  16px; width:80%; font-weight: bold; inherit;"> هل انت متأكد من انك تريد إلغاء الإشتراك من خدمة تاق بريس الاخبارية ؟ </p>
                <button class="login-btn1 button">
                   <span v-if="loading" class="px-1 ">الغاء الاشتراك</span>  &nbsp;
                   <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                   <span v-else class="">الغاء الاشتراك</span>
                </button>
            </form>
            <button class="login-btn" @click="goBack">رجـــــوع</button>
            <div class="bottom-container"></div>
        </div>
    </main>
</template>

<script>
// import axios from "axios";
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
export default {
   name: 'Unsubscribes',
   data() {
     return {
       loading: false,
     };
   },
    mounted() {
       if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
          this.$router.push({ path: "/" })
       }
    },
   methods: {
      goBack() {
         return this.$router.go(-1)
      },
      async unsubscribe () {
        const toast = useToast();
          let button = document.querySelector(".button");
          button.disabled = true;
        this.loading = true;
        await HTTPSPA.post("SpayUnsubscribe.php?username="+this.$cookie.getCookie("msisdn")+"&serviceId=2222&opratorID=2").then((response) => {
          if (response.data.status === 0) {
            toast.error("انت لست مشترك في خدمة تاق بريس الاخبارية", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من خدمة تاق برس الاخبارية ", { timeout: 2500 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
    h3 {
        /* font-family: 'Cairo', sans-serif; */
        font-weight:600;
        letter-spacing: 2px;
        font-size:32px;
    }
    p {
        /* font-family: 'Cairo', sans-serif; */
        letter-spacing: 0.1px;
        font-size:14px;
        color: #333333;
    }
    .header {
        position:relative;
        text-align:center;
        background: linear-gradient(60deg, rgb(18, 5, 68) 0%, rgba(20, 0, 255, 0.48) 100%);
        color:white;
    }
    .inner-header img{
        width:15%;
        position: relative;
        margin-top: 6%;
    }
    .inner-header {
        height:20vh;
        width:100%;
        margin: 0;
        padding: 0;
    }
    .flex { /*Flexbox for containers*/
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .bottom-container {
        display:flex;
        align-items:center;
    }
    a {
        text-decoration:none;
        margin: 0px 5px;
        /* font-family: 'Cairo', sans-serif; */
        letter-spacing:0px;
        font-size:14px;
        font-weight:600;
        color: #ffffff;
    }
    .waves {
        position:relative;
        width: 100%;
        height:15vh;
        margin-bottom:-7px; /*Fix for safari gap*/
        min-height:100px;
        max-height:150px;
    }

    .content {
        position:relative;
        height:100%;
        text-align:center;
        background-color: white;
    }

    /* Animation */
    .parallax > use {
        animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
    }
    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }
    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }
    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }
    @keyframes move-forever {
        0% {
            transform: translate3d(-90px,0,0);
         }
        100% { 
            transform: translate3d(85px,0,0);
        }
    }

    form {
        display:flex;
        width:90%;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        padding:10px;
        /* margin:10%; */
    }
    input {
        width:40%;
        padding:8px;
        margin:10px;
        height:45px;
        font-size:1rem;;
        border:none;
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
        border-radius:12px;
        outline:none;
        text-align:center;
        /* font-family: 'Cairo', sans-serif; */
        color: #2A4A8B;
    }
    .login-btn {
        font-size:1rem;
        border:none;
        height:44px;
        width:35%;
        margin:20px 10px;
        border-radius:5px;
        background-color:#2A4A8B;
        /* font-family: 'Cairo', sans-serif; */
        color:#ffffff
    }

    .login-btn1 {
        font-size:1rem;
        border:none;
        height:44px;
        width:40%;
        margin:20px 10px;
        border-radius: 5px;
        background-color:#e74945;
        /* font-family: 'Cairo', sans-serif; */
        color:#ffffff
    }
    button:hover {
        box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);
    }

    /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .form {
            max-width:80vh;
        }
        .waves {
            height:40px;
            min-height:40px;
        }
        .content {
            height:50vh;
        }
        h1 {
            font-size:24px;
        }
        .inner-header img {
            width: 20%;
            position: relative;
            margin-top: 6%;
        }
        input{
            width: 60%;
        } 
        .login-btn{
            width: 47%;
            margin: 10px 10px;
        }
        .login-btn1 {
            width: 50%;
            margin: 10px 10px;
        }
    }

    @media (max-width: 516px) {
        .form{
            max-width:50vh;
        }
        .waves {
            height:40px;
            min-height:40px;
        }
        .content {
            height:50vh;
        }
        h1 {
            font-size:24px;
        }
        .inner-header img {
            width: 30%;
        }
        input{
            width: 90%;
        }   
        .login-btn{
            width: 77%;
            margin: 10px 10px;
        }
        .login-btn1 {
            width: 90%;
            margin: 10px 10px;
        }
    }
</style>